import React from "react"
import styled from "@emotion/styled"
import { useSpring } from "react-spring"
import { useInView } from "react-intersection-observer"
import { mqHandheld, mqTabletHandheld } from "components/index.css"
import { H1, H2, P as Paragraph, Section } from "components/typography"
import SEO from "../components/seo"
import Quote from "components/quote"
import S from "components/sticker"

const triggerOnce = true
const threshold = 0.2

const AboutPage = () => {
  const [firstRef, firstInView] = useInView({ triggerOnce, threshold })
  const [secondRef, secondInView] = useInView({ triggerOnce, threshold })
  const [thirdRef, thirdInView] = useInView({ triggerOnce, threshold })
  const [stickerRef, stickerInView] = useInView({ triggerOnce, threshold: 1 })

  const firstProps = useSpring({
    opacity: firstInView ? 1 : 0,
    transform: firstInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  const secondProps = useSpring({
    opacity: secondInView ? 1 : 0,
    transform: secondInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  const thirdProps = useSpring({
    opacity: thirdInView ? 1 : 0,
    transform: thirdInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  return (
    <>
      <SEO title="Quienes Somos" />
      <H1>nosotros</H1>
      <Section style={firstProps}>
        <H2>Somos Compliance</H2>
        <Copy ref={firstRef}>
          <P>
            keep comp* somos una consultora especializada en cumplimiento
            normativo o Compliance.
          </P>

          <P>
            Nuestro equipo est&aacute; formado por{" "}
            <span>
              abogados y economistas con amplia experiencia en el asesoramiento
              de empresas y litigios corporativos.
            </span>
          </P>

          <P>
            A ra&iacute;z de las reformas llevadas a cabo en el C&oacute;digo
            Penal y las exigencias de la Uni&oacute;n Europea detectamos la
            necesidad que ten&iacute;an nuestros clientes de implantar planes de
            prevenci&oacute;n de delitos de una forma{" "}
            <span>eficaz y profesional</span>
          </P>

          <P>
            Nuestros sistemas de compliance son{" "}
            <span>proyectos dise&ntilde;ados a medida</span> de cada cliente,
            buscando <span>soluciones eficaces y adaptadas</span> a cada
            situaci&oacute;n.
          </P>
        </Copy>
      </Section>
      <Quote>
        <p>
          &ldquo;Nuestros sistemas de compliance son proyectos dise&ntilde;ados
          a medida de cada cliente, buscando soluciones eficaces y adaptadas a
          cada situaci&oacute;n&rdquo;.
        </p>
        <p>
          Carmen Tarr&oacute;n
          <br />
          Socia Directora
        </p>
      </Quote>
      <Section style={secondProps}>
        <H2>Compromiso y &eacute;tica</H2>
        <Copy ref={secondRef}>
          <P>
            Somos un equipo multidisciplinar de prestigiosos juristas y
            economistas con una trayectoria solida y rese&ntilde;able en sus
            respectivos campos.
          </P>

          <P>
            Los principios b&aacute;sicos de nuestra empresa{" "}
            <span>
              son la honestidad, la &eacute;tica, integridad, confidencialidad y
              profesionalidad
            </span>{" "}
            como eje de nuestro trabajo
          </P>

          <P>
            El <span>compromiso </span> que adquirimos con nuestros clientes
            hace que nuestros objetivos sean la{" "}
            <span>minuciosidad, cercan&iacute;a y la excelencia.</span>
          </P>
        </Copy>
      </Section>
      <StickerWrapper ref={stickerRef}>
        <Sticker inView={stickerInView} side="right">
          <P>&amp;</P>
          <P>crecemos</P>
          <P>contigo</P>
        </Sticker>
      </StickerWrapper>
      <Section style={{ marginTop: 10, ...thirdProps }}>
        <H2>Grandes Especialistas</H2>
        <Copy ref={thirdRef}>
          <P>
            keep comp* nace ante la demanda de nuestros clientes en{" "}
            <span>
              implementar planes de cumplimiento que prevengan los riesgos
            </span>{" "}
            a los que se enfrentan las empresas en su d&iacute;a a d&iacute;a.
          </P>

          <P>
            Ofrecemos a todos nuestros clientes un{" "}
            <span>trato personalizado.</span> Analizamos cada empresa, sus
            pol&iacute;ticas y procesos para despu&eacute;s{" "}
            <span>
              implementar planes que se adapten a la misma y aseguren la
              cobertura de todos los riesgos a los que est&aacute; expuesta
            </span>{" "}
            seg&uacute;n su actividad y circunstancias.
          </P>

          <P>
            <span>Queremos ser tu partner empresarial y trabajar juntos</span>{" "}
            para asegurar que puedas cumplir tus objetivos con total
            tranquilidad.
          </P>
        </Copy>
      </Section>
    </>
  )
}

export default AboutPage

const Copy = styled.div({
  flex: `1 1 70%`,
  maxWidth: 620,
  " > p:last-child": {
    margin: 0,
  },
})

const P = styled(Paragraph)({
  fontFamily: "Graphik",
  [mqHandheld]: {
    fontSize: 15,
    lineHeight: `23px`,
  },
})

const Sticker = styled(S)({
  background: `rgba(255, 85, 0, 1)`,
  marginRight: 20,
})

const StickerWrapper = styled.div({
  flex: 1,
  display: `flex`,
  justifyContent: `flex-end`,
  overflow: `hidden`,
  paddingBottom: 15,
})
