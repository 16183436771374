import React from "react"
import styled from "@emotion/styled"
import { a, useSpring } from "react-spring"
import { useInView } from "react-intersection-observer"

const Quote = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true })

  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? `scale(1, 1)` : `scale(0, 0)`,
    },
  })

  return (
    <Container style={props}>
      <div ref={ref}>{children}</div>
    </Container>
  )
}

export default Quote

const Container = styled(a.div)({
  color: `white`,
  fontFamily: `Recoleta`,
  fontWeight: 100,
  fontSize: 38,
  lineHeight: `45px`,
  marginTop: 60,

  "> div > p:last-child": {
    fontSize: 14,
    lineHeight: `17px`,
    textAlign: `right`,
    marginTop: 10,
  },
})
